import React, { useCallback, useEffect, useState } from 'react';
import { GridRowParams } from '@material-ui/data-grid';

import Spinner from '@atlaskit/spinner';
import { toast } from 'react-toastify';
import Search from '~/components/Search';

import { Container } from './styles';
import FormDefault from '~/components/FormDefault';
import api from '~/services/api';
import { Iclassificacao } from './types';
import InputText from '~/components/Inputs/InputText';
import InputSelect from '~/components/Inputs/InputSelect';
import { useForm } from 'react-hook-form';
import { schema } from './Validations';
import { yupResolver } from '@hookform/resolvers/yup';

const ClassificacaoParceiro: React.FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [iniInicializado, setInitInicializado] = useState(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [formData, setFormData] = useState<Iclassificacao>({
    cod_classificacao: {
      value: '',
      isInvalid: false,
      isRequired: false,
    },
    des_classificacao: {
      value: '',
      isInvalid: true,
      isRequired: true,
    },
    tipo_classificacao: {
      value: undefined,
      isInvalid: true,
      isRequired: true,
    },
  });

  const {
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onSubmit',
  });

  const tipoClassificacao: Array<{
    value: number;
    label: string;
  }> = [
    {
      value: 7,
      label: 'Administradora de Cartão',
    },
    {
      value: 0,
      label: 'Clientes (PJ)',
    },
    {
      value: 1,
      label: 'Clientes (PF)',
    },
    {
      value: 2,
      label: 'Fornecedores',
    },
    {
      value: 6,
      label: 'Produtos',
    },
    {
      value: 3,
      label: 'Seguradora',
    },
    {
      value: 5,
      label: 'Transportadoras',
    },
    {
      value: 4,
      label: 'Vendedores',
    },
  ];

  const tipoClassficacaoOrdenada = [...tipoClassificacao].sort((a, b) =>
    a.value > b.value ? 1 : -1,
  );

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = useCallback(() => {
    setFormData({
      ...formData,
      des_classificacao: {
        value: '',
        isInvalid: true,
        isRequired: true,
      },
      tipo_classificacao: {
        value: {
          value: undefined,
          label: 'Informe o Tipo',
        },
        isInvalid: true,
        isRequired: true,
      },
    });
    reset();
    setUpdate(false);
    setInitInicializado(false);
  }, [formData, reset]);

  useEffect(() => {
    if (iniInicializado) setInitInicializado(false);
  }, [formData]);

  const handleChangeDescricao = useCallback(
    (val: string) => {
      setFormData({
        ...formData,
        des_classificacao: {
          ...formData.des_classificacao,
          value: val.trimStart().toUpperCase(),
          isInvalid: val.trim() === '',
        },
      });

      setValue('des_classificacao', val.trimStart().toUpperCase());
    },
    [formData, setValue],
  );

  const handleChangeTipo = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setFormData({
        ...formData,
        tipo_classificacao: {
          ...formData.tipo_classificacao,
          value: val,
          isInvalid,
        },
      });
      setValue('tipo_classificacao', val);
    },
    [formData, setValue],
  );
  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const { cod_classificacao, des_classificacao, tipo_classificacao } = row;

    setValue(
      'tipo_classificacao',
      tipo_classificacao >= 0
        ? tipoClassificacao.find((item) => item.value === tipo_classificacao)
        : undefined,
    );
    setValue('des_classificacao', des_classificacao);

    setFormData({
      ...formData,
      cod_classificacao: {
        value: cod_classificacao,
        isInvalid: false,
        isRequired: false,
      },
      des_classificacao: {
        value: des_classificacao,
        isInvalid: false,
        isRequired: true,
      },
      tipo_classificacao: {
        value:
          tipo_classificacao >= 0
            ? tipoClassificacao.find(
                (item) => item.value === tipo_classificacao,
              )
            : undefined,
        isInvalid: false,
        isRequired: true,
      },
    });

    setUpdate(true);
    setLoader(false);
    setShowSearch(false);
  };

  const onSave = handleSubmit(async () => {
    const { tipo_classificacao, des_classificacao } = getValues();

    try {
      if (
        des_classificacao.trim() === 'NENHUM' ||
        des_classificacao.trim() === 'NENHUMA' ||
        des_classificacao.trim() === 'NENHUM(A)'
      ) {
        return toast.warn(
          'Descrição contém palavra não permitida "Nenhum/Nenhuma".',
        );
      }
      if (isUpdate) {
        const { data } = await api.put(
          `/classificacao-parceiro/${formData?.cod_classificacao.value}`,
          {
            tipo_classificacao: tipo_classificacao.value,
            des_classificacao,
          },
        );
        if (data.success) {
          toast.success(data.message);
          resetFormData();
          setShowSearch(true);
          return;
        }
      }
      const { data } = await api.post('/classificacao-parceiro', {
        tipo_classificacao: tipo_classificacao.value,
        des_classificacao,
      });
      if (data.success) {
        toast.success(data.message);
        resetFormData();
      }
    } finally {
      setLoader(false);
    }
  });

  const handleDelete = useCallback(async () => {
    const { data } = await api.delete(
      `/classificacao-parceiro/${formData.cod_classificacao.value}`,
    );
    resetFormData();
    setShowSearch(true);
    toast.success(data.message);
  }, [formData.cod_classificacao.value, resetFormData]);

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={175}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={175}
          title="Classificação de Parceiros"
          codigoRegistro={[
            {
              value: formData.cod_classificacao.value,
              des_campo: 'Código',
            },
          ]}
          onSave={async () => {
            await onSave();
          }}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={handleDelete}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
        >
          <div className="row">
            {/* Number */}
            <div className="col-sm-12 col-md-9">
              <InputText
                label="Descrição"
                value={formData.des_classificacao.value}
                maxLength={50}
                placeholder="Informe a descrição da classificação de parceiros"
                isEmpty
                isNull
                isUndefined
                isRequired={formData.des_classificacao.isRequired}
                setInvalid={!!errors.des_classificacao}
                iniInicializado={!!errors.des_classificacao}
                onChange={(newValue: string) => {
                  handleChangeDescricao(newValue);
                }}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <InputSelect
                options={tipoClassficacaoOrdenada}
                label="Tipo"
                isRequired
                setInvalid={!!errors.tipo_classificacao}
                placeholder="Informe o Tipo "
                iniInicializado={!!errors.tipo_classificacao}
                onChange={(newValue: any, isInvalid = true) => {
                  handleChangeTipo(newValue, isInvalid);
                }}
                value={formData.tipo_classificacao.value}
              />
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default ClassificacaoParceiro;
